.modal-close-btn {
    position: absolute;
    right: 1%;
    top: 1%;
    z-index: 100 !important;
}

.modal-close-btn:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .token-cnt {
        min-width: 85% !important;
        padding: 10px 10px 20px 10px !important;
    }
    .md-img-cnt {
        max-width: 100% !important;
        height: 60% !important;
    }
    /* .md-img-cnt img {
        height: 50% !important;
    } */
    .md-txt {
        width: 100% !important;
    }
    .mn-cnt {
        grid-template-columns: 1fr !important;
    }
    .fau-cnt {
        padding: 20px 20px !important;
    }
}

@media (max-width: 806px) {
    .sm-txt-cnt {
        width: 70dvw !important;
    }
}

@media (max-width: 720px) {
    .sm-txt-cnt {
        width: 70dvw !important;
    }
}

@media (max-width: 600px) {
    .sm-txt-cnt {
        width: 80dvw !important;
    }
}

@media (max-width: 540px) {
    .sm-title-m {
        font-size: 1.2rem !important;
    }

    .sm-p-m {
        font-size: .9rem !important;
    }

    .t-name {
        font-size: 2.5rem !important;
    }
}

@media (max-width: 500px) {
    .sm-txt-cnt {
        width: 85dvw !important;
    }
}

@media (max-width: 415px) {
    .sm-txt-cnt {
        width: 95dvw !important;
    }
}

@media (max-width: 400px) {
    .fau-cnt {
        padding: 20px 10px !important;
    }
    .sm-title-m {
        font-size: 1rem !important;
    }

    .sm-p-m {
        font-size: .7rem !important;
        /* width: 85% !important; */
    }
    .t-name {
        font-size: 2rem !important;
    }
}
