@media (max-width: 725px) {
    .dis-sr-wrap {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }
    .sr-in-wrap {
        width: 100% !important;
    }
}

@media (max-width: 390px) {
    .home-filters {
        gap: 10px !important;
    }
}

@media (max-width: 310px) {
    .dis-tog-wrap {
        gap: 10px !important;
    }
}