@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@700&family=Bebas+Neue&family=Inter:wght@400;600&family=Montserrat:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --backgroundColor: #ECF0F3;
}

* {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: 100dvw;
  /* overflow: hidden; */
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100%;
  background: var(--backgroundColor);
}

#root canvas {
  display: block;
  position: fixed;
  z-index: -10;
  top: 0;
}

.cursor-outline {
  width: 250px;
  height: 250px;
  background-color: #745AC3;
  animation: colorChange 8s infinite;
  filter: blur(130px);
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
}

input:focus-visible {
  box-shadow: none !important;
}

.radio:focus-within {
    box-shadow: none !important;
}

@keyframes colorChange {
  0% {
    background-color: #6B92E5;
  }

  25% {
    background-color: #745AC3;
  }

  50% {
    background-color: #F88AB0;
  }

  75% {
    background-color: #745AC3;
  }

  100% {
    background-color: #6B92E5;
  }
}

.ph {
  display: none;
}

.loader {
  height: 10px;
  background: black;
}

.model {
  z-index: 2 !important;
}

@media (max-width: 991px) {
  .pc {
    display: none !important;
  }

  .ph {
    display: block !important;
  }

  .cursor-outline {
    display: none !important;
  }

  .all-cnt {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .all-title {
    font-size: 3rem !important;
    line-height: 90% !important;
  }
}

@media (max-width: 560px) {
  .side-btns {
    display: none !important;
  }
}