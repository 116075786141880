.form-inputs:focus {
  box-shadow: none !important;
  border: 2px solid #745ac3 !important;
}

.form-inputs:hover {
  border: 2px solid #745ac3 !important;
}

.form-inputs::placeholder {
  color: #3b3b3b;
  font-family: Montserrat !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
}

.txt hr {
  width: 40%;
  border: rgba(0, 0, 0, 0.2) 0.5px solid;
}

.auth-btn:hover {
  background: linear-gradient(
    90deg,
    #745ac3 66.98%,
    rgba(248, 138, 176, 0.8) 100%
  ) !important;
}

.auth-btn:active {
  background: linear-gradient(
    90deg,
    #745ac3 66.98%,
    rgba(248, 138, 176, 0.8) 100%
  ) !important;
  scale: 0.98;
}

.cr-btn:hover {
  background: #745ac3 !important;
  color: white;
}

.cr-btn:active {
  background: #745ac3 !important;
  color: white;
  scale: 0.98;
}

.lg {
  text-decoration: underline;
  color: #745ac3;
}

.portalLink {
  color: #745ac3;
  text-decoration: underline;
  font-weight: 900;
}

@media (max-width: 991px) {
  .auth-wrap {
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 70% !important;
  }
}

@media (max-width: 565px) {
  .auth-wrap {
    width: 90% !important;
  }
  .au-txt {
    font-size: 2.2rem !important;
    margin-bottom: 10px !important;
  }
}
