@media (max-width: 991px) {
    .items-wrap {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
    .md-cnt {
        height: 50dvh !important;
        width: 100% !important;
    }
}

@media (max-width: 575px) {
    .items-wrap {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 520px) {
    .md-cnt {
        width: 100% !important;
    }
}