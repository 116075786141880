@media (max-width: 540px) {
    .sm-title {
        font-size: 1.2rem !important;
    }
    .sm-p {
        font-size: .9rem !important;
    }
    .pr-btns {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        gap: 10px !important;
    }
}

@media (max-width: 375px) {
    .sm-title {
        font-size: 1rem !important;
    }
    .sm-p {
        font-size: .7rem !important;
        /* width: 85% !important; */
    }
}