.bg-txt {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-btn:hover {
    background: linear-gradient(90deg, #745AC3 66.98%, rgba(248, 138, 176, .8) 100%) !important;
}

.home-btn:active {
    background: linear-gradient(90deg, #745AC3 66.98%, rgba(248, 138, 176, .8) 100%) !important;
}

@media (max-width: 1295px) {
    .bg-txt {
        font-size: 19rem !important;
    }
    
    .bx-txt {
        font-size: 4rem !important;
    }
    
    .rs-txt {
        font-size: 4rem !important;
    }
}

@media (max-width: 1240px) {
    .bg-txt {
        font-size: 18rem !important;
    }

    .bx-txt {
        font-size: 3rem !important;
    }
    .rs-txt {
        font-size: 3rem !important;
    }
}

@media (max-width: 1186px) {
    .bg-txt {
        font-size: 17rem !important;
    }
    .bx-txt {
        font-size: 2.5rem !important;
    }
    .bx {
        border-radius: .3rem !important;
        min-width: 270px !important;
    }
    .rs-txt {
        font-size: 2.5rem;
    }
}

@media (max-width: 1131px) {
    .bg-txt {
        font-size: 16rem !important;
    }
}

@media (max-width: 1076px) {
    .bg-txt {
        font-size: 15rem !important;
    }
}

@media (max-width: 1021px) {
    .bg-txt {
        font-size: 14rem !important;
    }
    .hm-txt-m {
        margin-right: 70px !important;
    }
    .bx {
        border-radius: .2rem !important;
    }
}

@media (max-width: 991px) {
    /* .img-cnt {
        max-height: 40dvh !important;
    } */
    
}

@media (max-width: 792px) {
    .bg-txt {
        font-size: 13rem !important;
    }
    .hm-txt-m {
        margin-right: 20px !important;
    }
    .bx {
        min-width: 250px !important;
    }
    .hm-cnt {
        min-height: 90dvh !important;
    }
}

@media (max-width: 738px) {
    .bg-txt {
        font-size: 12rem !important;
    }
    .hm-cnt {
        min-height: 80dvh !important;
    }
}

@media (max-width: 683px) {
    .bg-txt {
        font-size: 11rem !important;
    }
}

@media (max-width: 628px) {
    .bg-txt {
        font-size: 10rem !important;
    }
    .hm-cnt {
        min-height: 60dvh !important;
    }
}

@media (max-width: 573px) {
    .bg-txt {
        font-size: 9rem !important;
        margin-top: 15px;
    }
    .hm-txt-m {
        margin-right: 15px !important;
        margin-top: 50px;
    }
    .rs-txt {
        font-size: 2rem !important;
    }
    .bx {
        min-width: 220px !important;
        margin-top: -5px !important;
    }
    .bx-txt {
        font-size: 2rem !important;
    }
    .m-txt {
        margin-top: 0px !important;
    }
    .home-btn {
        font-size: 1.5rem !important;
        padding: 0px 15px !important;
        border-radius: .2rem !important;
    }
    .home-btn svg {
        height: 18px !important;
        width: 15px !important;
    }
}

@media (max-width: 519px) {
    .bg-txt {
        font-size: 8rem !important;
    }
    .hm-cnt {
        min-height: 50dvh !important;
    }
}

@media (max-width: 464px) {
    .bg-txt {
        font-size: 7rem !important;
    }
    .bx {
        min-width: none !important;
    }
    .mn-cnt {
        left: -25px !important;
    }
    .img-cnt img{
        min-width: 16rem !important;
    }
    .hm-txt-m {
        margin-top: 60px !important;
        margin-left: -10px !important;
    }
    .bx {
        min-width: 210px !important;
    }
}

@media (max-width: 435px) {
    .hm-txt-m {
        margin-left: -20px !important;
    }
    .hm-cnt {
        min-height: 40dvh !important;
    }
}

@media (max-width: 424px) {
    .hm-txt-m {
        margin-left: -30px !important;
    }
}

@media (max-width: 410px) {
    .bg-txt {
        font-size: 6.5rem !important;
    }
    .img-cnt img {
        min-width: 14rem !important;
        margin-top: 20px !important;
    }
    .hm-txt-m {
        margin-left: -10px !important;
    }
}

@media (max-width: 400px) {
    .bx-txt {
        font-size: 1.5rem !important;
    }
    .rs-txt {
        font-size: 1.5rem !important;
    }
    .bx {
        min-width: 155px !important;
        padding: 0px !important;
        padding-left: 5px !important;
    }
    .hm-txt-m {
        margin-left: 0px !important;
    }
    .home-btn {
        font-size: 1.2rem !important;
        padding: 0 15px !important;
        height: 30px !important;
    }
    .home-btn svg {
        height: 14px !important;
    }
}

@media (max-width: 382px) {
    .bg-txt {
        font-size: 6rem !important;
    }
    .hm-cnt {
        min-height: 50dvh !important;
    }
}

@media (max-width: 354px) {
    .bg-txt {
        font-size: 5.5rem !important;
    }
    .hm-txt-m {
        margin-left: -10px !important;
    }
}

@media (max-width: 327px) {
    .bg-txt {
        font-size: 5rem !important;
    }
}

@media (max-width: 300px) {
    .bg-txt {
        font-size: 4.5rem !important;
    }
}